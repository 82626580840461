import React from 'react';
import {Button} from "antd";
import i18n from "../i18n";
import {QrcodeOutlined} from "@ant-design/icons";
import { observer, inject } from 'mobx-react';
import {
    Modal
} from 'antd';

@inject('clientStore')
@observer
class WhatsappAuthenticator extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = () => ({
            isModalOpen:false,
            instanceName:this.props.clientStore.value
        });
        this.state = this.initialState();
        this.openModal = ()=> {
            this.setState({
                isModalOpen:true,
                instanceName:this.props.clientStore.value
            })
        }

        this.closeModal = ()=> {
            this.setState({
                isModalOpen:false,
                instanceName:''
            })
        }
    }

    render() {
        const {isModalOpen, instanceName} = this.state

        return (
            <>
                <Button
                    title={i18n.t('Whatsapp QR')}
                    shape="circle"
                    icon={<QrcodeOutlined />}
                    onClick={this.openModal}
                    style={{
                        backgroundColor: '#EAECF2',
                        border: 'none',
                    }}
                />
                {isModalOpen && <Modal visible={isModalOpen} onOk={this.closeModal} closable={false} cancelButtonProps={{
                    style:{
                        display:'none'
                    }
                }}
                width={'80%'}>
                    <iframe src={`${process.env.REACT_APP_WHATSAPP_SERVICE_URL}/QRAuth/${instanceName}`} width={'100%'} style={{
                        height:'60vh',
                        border:'none'
                    }}/>
                </Modal>}
            </>
        )
    }
}

export default WhatsappAuthenticator;
