import { lazy } from 'react';

const options = [
  {
    key: 1,
    path: '/',
    component: lazy(() => import('../dashboard/Content')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/unit-list',
    component: lazy(() => import('../unit-list/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/unit-type',
    component: lazy(() => import('../unit-type/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/project',
    component: lazy(() => import('../project/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/reports',
    component: lazy(() => import('../reports/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/customer-list',
    component: lazy(() => import('../customer/List')),
    exact: true,
  },
  // {
  //   key: Math.random(),
  //   path: '/contact-person-type',
  //   component: lazy(() => import('../contact-person-type/List')),
  //   exact: true,
  // },
  {
    key: Math.random(),
    path: '/invoice',
    component: lazy(() => import('../invoice/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/receipt',
    component: lazy(() => import('../receipt/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/receipt-draft',
    component: lazy(() => import('../receipt-draft/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/expense',
    component: lazy(() => import('../expense/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/expense-draft',
    component: lazy(() => import('../expense-draft/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/payment-notification',
    component: lazy(() => import('../notification-payment/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/archive-notification',
    component: lazy(() => import('../notification-archive/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/ending-contract-notification',
    component: lazy(() => import('../notification-contract/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/offer',
    component: lazy(() => import('../offer/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/account-statement',
    component: lazy(() => import('../account-statement/Content')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/investor',
    component: lazy(() => import('../investor/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/investor-payment',
    component: lazy(() => import('../investor-payment/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/investor-transaction',
    component: lazy(() => import('../investor-transaction/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/sms',
    component: lazy(() => import('../sms/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/archive-contracts',
    component: lazy(() => import('../archive-contracts/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/cancel-contracts',
    component: lazy(() => import('../cancel-contracts/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/rent-contract',
    component: lazy(() => import('../rent-contract/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/sale-contract',
    component: lazy(() => import('../sale-contract/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/my-profile',
    component: lazy(() => import('../my-profile/Main')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/user-profile',
    component: lazy(() => import('../user-profile/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/branches',
    component: lazy(() => import('../branches/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/safe',
    component: lazy(() => import('../safe/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/propertyType',
    component: lazy(() => import('../propertyType/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/cities',
    component: lazy(() => import('../city/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/employees',
    component: lazy(() => import('../employee/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/print-setting',
    component: lazy(() => import('../print-setting/List')),
    exact: true,
  },
];
export default options;
