/* eslint-disable max-classes-per-file */
import { observable } from 'mobx';
import { persist, create } from 'mobx-persist';

class Sections {
  @persist('list') @observable value = [];

  @observable clear() {
    this.value = [];
  }
}
class Token {
  @persist @observable value = '';

  @observable clear() {
    this.value = '';
  }
}
class User {
  @persist('object') @observable value = {};

  @observable clear() {
    this.value = {};
  }
}
class Client {
  @persist @observable value = '';

  @observable clear() {
    this.value = '';
  }
}
class Notification {
  @persist @observable PaymentValue = 0;

  @persist @observable EndingValue = 0;

  @observable clearPayment() {
    this.PaymentValue = 0;
  }

  @observable clearEnding() {
    this.EndingValue = 0;
  }
}

// const store = (window.store = new Store());
const notification = new Notification();
const userStore = new User();
const tokenStore = new Token();
const sectionsStore = new Sections();
const clientStore = new Client();
const hydrate = create({
  storage: localStorage,
  jsonify: true,
});
hydrate(process.env.REACT_APP_USER_LOCALSTORAGE_KEY, userStore).then(() => {});
hydrate(process.env.REACT_APP_TOKEN_LOCALSTORAGE_KEY, tokenStore).then(
  () => {}
);
hydrate(process.env.REACT_APP_SECTIONS_LOCALSTORAGE_KEY, sectionsStore).then(
  () => {}
);
hydrate(process.env.REACT_APP_CLIENT_LOCALSTORAGE_KEY, clientStore).then(
  () => {}
);

export default {
  userStore,
  tokenStore,
  sectionsStore,
  notification,
  clientStore,
};
